import { observable } from 'mobx'
import { Model, Store } from 'store/Base'
import { Warehouse } from './Warehouse'
import { ArticleTypeWarehouseStore } from './ArticleTypeWarehouse'
import { ArticleTypeStore } from './ArticleType'

export class StorageLocation extends Model {
  static backendResourceName = 'storage_location'
  static idColor = 'test'
  static forcedBackgroundColor = 'cadetblue'


  getUrl() {
    return `/warehouse/stock?limit=25&currentPage=1&.warehouse.storage_locations%3Ain=${this.id}&.article_type.storage_locations.storage_location%3Ain=${this.id}`
  }

  getIdIcon() {
    return 'box'
  }

  get _labelContent() {
    return this.code
  }

  static omitFields = [
    'used',
    'deleted'
  ]

  @observable id = null
  @observable code = ''
  @observable name = ''
  @observable deleted = false
  @observable capacity = null
  @observable freeCapacity = 0
  @observable sequenceNumber = null
  @observable preferredArticlesCount = 0

  // Annotations
  @observable used = 0

  relations() {
    return {
      warehouse: Warehouse,
      articleTypeWarehouses: ArticleTypeWarehouseStore,
      preferredArticles: ArticleTypeStore,
    }
  }
}

export class StorageLocationStore extends Store {
  Model = StorageLocation
  static backendResourceName = 'storage_location'
}
